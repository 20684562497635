import React, {useEffect, useState} from "react";
import {alpha, Box, Button, Card, CircularProgress, MenuItem, Stack, TextField, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {firebase} from "../firebase/clientApp";
import {Edit} from "@mui/icons-material";
import axios from "axios";
import {API_URL, THEME_CONFIG} from "../config";
import {CustomAlert} from "./shared/CustomAlert";

export const DisplayName = ({userData, setUserData}) => {
    const {id} = useParams()
    const [state, setState] = useState({displayName: '', teamName: ''})
    const [error, setError] = useState(false)
    const [setting, setSetting] = useState(false)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (userData) {
            setState(userData)
        }
    }, [userData])

    const handleSetDisplayName = (event) => {
        setState({...state, displayName: event.target.value})
    }

    const handleSetTeam = (event) => {
        setState({...state, teamName: event.target.value})
    }

    const submitDisplayName = async (event) => {
        event.preventDefault()
        setSetting(true)
        let uid = firebase.auth().currentUser.uid
        await axios.post(`${API_URL}/events/${id}/users`,
            {
                user: uid,
                displayName: state.displayName,
                teamName: state.teamName
            })
            .then(res => {
                    setUserData({displayName: state.displayName ?? '', teamName: state.teamName ?? ''})
                    setError("")
                    setEditing(false)
            })
            .catch((err) => {
                console.log("NickName già preso!!!", err?.response?.data?.message ?? "There was an error")
                setError(err?.response?.data?.message ?? "There was an error")
            })
            .finally(() => {
                setSetting(false)
            })
    }

    return (
        <Box>
            {
                ((editing) || userData.displayName==='' || !userData.displayName || userData.teamName==='' || !userData.teamName) ?
                    <Box component={Card} padding={2} sx={{background: alpha(THEME_CONFIG.accent, 0.6)}}>
                        <Typography textAlign={'center'} fontWeight={'bold'} fontSize={20} sx={{pb: 2}}>
                            Inserisci nome e cognome per interagire
                        </Typography>
                        <form onSubmit={submitDisplayName}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{style: {fontSize: '1.2rem'}}}
                                placeholder="Nome e cognome"
                                id="displayName"
                                value={state.displayName}
                                onChange={handleSetDisplayName}/>
                            <br/><br/>
                            <Typography textAlign={'center'} fontWeight={'bold'} fontSize={20} sx={{pb: 2}}>
                                Seleziona la tua squadra
                            </Typography>
                            <TextField
                                fullWidth
                                required
                                select
                                inputProps={{style: {fontSize: '1.2rem'}}}
                                placeholder="Squadra"
                                id="teamName"
                                value={state.teamName}
                                onChange={handleSetTeam}
                            >
                                {["VERDE","ROSSO","AZZURRO"].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        Squadra {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Box pt={2}>
                                {setting ? <CircularProgress/> :
                                    <Button variant={'wordcloud'} type={'submit'}
                                            disabled={
                                        (editing && userData.displayName === state.displayName && userData.teamName === state.teamName)
                                                || (!state.displayName || !state.teamName)
                                    }>
                                        invia
                                    </Button>}
                                {editing && <Button sx={{color: 'white'}} onClick={() => setEditing(false)}>
                                    Annulla
                                </Button>}
                                <CustomAlert open={!!error} message={error} setOpen={setError} severity={'error'}/>
                            </Box>
                        </form>
                    </Box>
                    :
                    <Box>
                        <Typography textAlign={'center'} fontSize={18} sx={{pb: 1}}>
                            Nome e cognome:
                        </Typography>
                        <Typography textAlign={'center'} fontSize={20} sx={{pb:1}} fontWeight={'bold'}>
                            {userData.displayName}
                        </Typography>
                        <Typography textAlign={'center'} fontSize={20} sx={{pb:1}} fontWeight={'bold'}>
                        {userData.teamName ? `TEAM: ${userData.teamName}` : ''}
                        </Typography>
                        <Button color={'accent'} startIcon={<Edit/>} size={'small'} onClick={() => setEditing(!editing)}>
                            modifica
                        </Button>
                    </Box>
            }

        </Box>
    )
}
