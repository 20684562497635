import React, {useEffect, useState} from "react";
import {auth, firebase, firestore} from "../firebase/clientApp";
import VoteContent from "../components/VoteContent";
import {useParams} from "react-router-dom";
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab, SpeedDial, SpeedDialAction,
    Stack,
    Typography
} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import axios from "axios";
import {API_URL} from "../config";
import {AssignmentInd, ContentPasteSearch, Slideshow} from "@mui/icons-material";
import pleshDark from "../theme/pleshDark";

export default function Vote() {
    const {id} = useParams()
    const [user, setUser] = useState(null);
    const [event, eventLoading, eventError] = useDocument(firestore.doc('users/plesh/events/' + id))
    const [userData, setUserData] = useState({displayName: '', teamName: ''})

    const [loading, setLoading] = useState(true);

    async function loginUser() {
        await auth.signInAnonymously().then(() => {}).catch(alert);
    }

    useEffect(async () => {
        setLoading(true)
        await loginUser()
        firebase.auth().onAuthStateChanged(async (user) => {
            setUser(user);
            await axios.post(`${API_URL}/events/${id}/users`,{user: user.uid})
                .then(res =>
                    setUserData({displayName: res.data.displayName ?? '', teamName: res.data.teamName ?? ''})
                )
                .catch()
        })
        setLoading(false)
    }, [])

    if (loading) return <></>

    return (
        <Box>
            {(event && !eventLoading && !eventError) &&
                <Container maxWidth={'sm'} className={'vote'} style={{maxHeight: '100%'}}>
                    <VoteContent user={user} event={event} loading={loading} userData={userData} setUserData={setUserData}/>
                    <VediSlideSpeedDial/>
                </Container>
            }
        </Box>
    )
}

export const VediSlideSpeedDial = () => {
    const [selectedSlide, setSelectedSlide] = useState(null)

    const handleSetSlide = (caso) => {
        setSelectedSlide(caso)
    }

    const handleCloseSlide = () => {
        setSelectedSlide(null)
    }

    return <Box zIndex={1800} >
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ zIndex: 1800, position: 'absolute', bottom: 32, right: 32 }}
            icon={<Slideshow fontSize={'large'} />}
        >
            {Object.values(slides).map((slide, index) => (
                <SpeedDialAction
                    key={slide.name}
                    sx={{zIndex: 1800}}
                    icon={<ContentPasteSearch/>}
                    tooltipOpen
                    tooltipTitle={slide.name}
                    onClick={() => handleSetSlide(slide)}
                />
            ))}
        </SpeedDial>
        <Dialog open={!!selectedSlide}
                onClose={handleCloseSlide}
                maxWidth={false}
                fullWidth
                scroll={'paper'}
                sx={{zIndex: 1800, '& .MuiDialog-paper': {
                        margin: 0, // Sovrascrive i margini
                        width: '96%', // Imposta la larghezza al 100%
                    },}}
                PaperProps={{ sx: { borderRadius: "1rem", background: pleshDark.palette.secondary.main } }}
        >
            <DialogTitle>
                {selectedSlide?.name}
            </DialogTitle>
            <Stack px={0}>
                <iframe allowFullScreen="allowfullscreen" scrolling="no" className="fp-iframe"
                        style={{border: "0px", width: "100%", height: "400px"}}
                        src={selectedSlide?.link}>
                </iframe>
            </Stack>
            <DialogActions>
                <Button variant={'contained'} onClick={handleCloseSlide}>
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
    </Box>;
}

export const VediSlideFab = ({_open}) => {
    const [extended, setExtended] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        //setExtended(!extended)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popupId = open ? 'simple-popover' : false;

    return <Box zIndex={1600}>
        <Fab variant={extended ? "extended" : "circular"}
             color={'primary'}
             aria-describedby={popupId}
             sx={{ zIndex: 1500, position: 'absolute', bottom: 34, right: 32, /*boxShadow: '1px 1px 10px 0px white' */}}
             onClick={handleClick}>
            <Slideshow fontSize={'large'} />
            {extended ? "Slide" : null}
        </Fab>
        <Typography variant={'body2'} sx={{ zIndex: 1500, position: 'absolute', bottom: 12, right: 27, }}>
            Vedi Slide
        </Typography>
        <Dialog
            id={popupId}
            fullWidth
            maxWidth={'xl'}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
                zIndex: 1500,
            }}
            PaperProps={{
                sx: {
                    paddingY: 1,
                    background: pleshDark.palette.secondary.main
                }
            }}
        >
            <DialogContent sx={{p:1, pr:3}}>
                <Typography gutterBottom variant={'h5'} fontWeight={'bold'} sx={{px:3}}>
                    Vedi slide
                </Typography>
                <Stack>
                    <iframe allowFullScreen="allowfullscreen" scrolling="no" className="fp-iframe"
                            style={{border: "0px", width: "100%", height: "400px"}}
                            src="https://heyzine.com/flip-book/1068f91f22.html">
                    </iframe>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={handleClose}>
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
    </Box>;
}

export const slides = {
    'ciardullo': {
        id: 'ciardullo',
        name: "Controllo glicemico",
        link: "https://heyzine.com/flip-book/1068f91f22.html"
    },
    'truccinardi': {
        id: 'truccinardi',
        name: "Controllo peso",
        link: "https://heyzine.com/flip-book/070d07d589.html"
    },
    'trico': {
        id: 'trico',
        name: "Controllo rischio",
        link: "https://heyzine.com/flip-book/c54a77ef7d.html"
    },
    'eventiAvversi': {
        id: 'eventiAvversi',
        name: "Eventi Avversi",
        link: "https://heyzine.com/flip-book/d410a82ab0.html"
    },
}