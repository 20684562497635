export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#24bc5f', '#24bc5f'],
        home_question: ['#24bc5f', '#24bc5f'],
        applausometro: ['#24bc5f', '#24bc5f'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#24bc5f', '#24bc5f'],
        qna: ['#24bc5f', '#24bc5f'],
    },
    primary: '#24bc5f',
    secondary: '#263238',
    accent: '#ee9527',
    text: '#eceff1',
    background:"#090d0d",
    poll:{
        default:"rgba(36,188,95,0.4)",
        answered:"#24bc5f",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}